<template>
    <div class="main_bd">
        <div class="head_title">Membership Management</div>
        <div class="in_title">Membership in minipass</div>
        <div class="fileter">
            <div style="margin-top: 10px">
                <span class="date" style="margin-right: 10px;">
                    <a-input v-model="q" placeholder="phone / name" style="width: 150px" />
                </span>
                <span class="date">
                    <a-date-picker v-model="start_date" placeholder="Start date" style="width: 100%" />
                </span>
                <span style="margin: 0 10px">to</span>
                <span class="date">
                    <a-date-picker v-model="end_date" placeholder="End date" style="width: 100%" :disabled-date="disabledEndDate" />
                </span>
                <button class="done_btn" @click="fliterData">Search</button>
            </div>
        </div>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey='data => data.id' :columns="columns" :data-source="membership_list">
                <a slot="index" slot-scope="text, data">
                    <p>No.{{ data.idx }}</p>
                </a>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="turnPage" :pageSize="10" v-model="page" :total="total_count"
                show-less-items />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from "moment";

    const columns = [
        {
            title: "No.",
            dataIndex: "index"
        },
        {
            title: "Phone",
            dataIndex: "phone",
            width: 150
        },
        {
            title: "Email",
            dataIndex: "email"
        },
        {
            title: "User name",
            dataIndex: "name"
        },
        {
            title: "Sex",
            dataIndex: "gender"
        },
        {
            title: "DOB",
            dataIndex: "birthday"
        },
        {
            title: "Join time",
            dataIndex: "pro_jointime"
        },
        {
            title: "Sub type",
            dataIndex: "pro_sub_type"
        },
        {
            title: "Expiration time",
            dataIndex: "pro_expiredtime"
        },
        {
            title: "Auto renew",
            dataIndex: "pro_autorenew_text"
        },
        {
            title: "Status",
            dataIndex: "pro_status_text"
        }
    ];

    export default {
        name: 'Member_ship',
        components: {
        },
        data() {
            return {
                columns,
                page: 1,
                start_date: '',
                end_date: '',
                q: '',
                membership_list: [],
                total_count: 0,
                total_page: 0,
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
            this.search();
        },
        watch: {
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                getMembershipList: 'guest/getMembershipList',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async search() {
                let res = await this.getMembershipList({
                    q: this.q,
                    stimestamp: this.start_date ? moment(this.start_date + ' 00:00:00').valueOf() : '',
                    etimestamp: this.end_date ? moment(this.end_date + ' 23:59:59').valueOf() : '',
                    page: this.page
                });

                this.membership_list = res.list;
                this.total_count = res.total_count;
                this.total_page = res.total_page;
            },
            disabledEndDate(current) {
                return current && current.valueOf() <= moment(this.start_date).valueOf();
            },
            fliterData(){
                console.log(this.start_date, this.end_date)
                this.search();
            },
            handleOk() {
                this.detailVisible = false;
            },
            turnPage(e) {
                this.search();
            }
        }
    };
</script>

<style>
    .in_title {
        font-size: 16px;
        text-align: center;
    }
    .fileter {
        text-align: right;
        margin-top: 30px;
        font-size: 16px;
    }
    .date {
        display: inline-block;
        width: 200px;
    }
    .done_btn {
        width: 82px;
        height: 28px;
        border: none;
        border-radius: 8px;
        background-color: rgba(116, 39, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-family: 'Helvetica';
        font-size: 16px;
        margin-left: 10px;
    }
</style>
