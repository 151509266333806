<template>
    <div class="main_bd">
        <div class="head_title">Membership Transactions</div>
        <div class="fileter">
            <div style="margin-top: 10px">
                <span class="date">
                    <a-date-picker v-model="start_date" placeholder="Start date" style="width: 100%" />
                </span>
                <span style="margin: 0 10px">to</span>
                <span class="date">
                    <a-date-picker v-model="end_date" placeholder="End date" style="width: 100%" :disabled-date="disabledEndDate" />
                </span>
                <button class="done_btn" @click="search">Done</button>
            </div>
        </div>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey='data => data.id' :columns="columns" :data-source="transaction_list">
                <a slot="index" slot-scope="text, data">
                    <p>No.{{ data.idx }}</p>
                </a>
                <template slot="action" slot-scope="text, data">
                    <a-button type="primary" size="small" style="background-color: #6200eed9; border-color: #6200eed9"
                        @click="toDetail(data)">
                        details
                    </a-button>
                </template>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="turnPage" :pageSize="10" v-model="page" :total="total_count"
                show-less-items />
        </div>
    </div>
</template>

<style>
    .in_title {
        font-size: 16px;
        text-align: center;
    }
    .fileter {
        text-align: right;
        margin-top: 30px;
        font-size: 16px;
    }
    .date {
        display: inline-block;
        width: 200px;
    }
    .done_btn {
        width: 82px;
        height: 28px;
        border: none;
        border-radius: 8px;
        background-color: rgba(116, 39, 255, 1);
        color: rgba(255, 255, 255, 1);
        font-family: 'Helvetica';
        font-size: 16px;
        margin-left: 10px;
    }
</style>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from "moment";

    const columns = [
        {
            title: "Transaction Id",
            dataIndex: "transaction_id"
        },
        {
            title: "User Id",
            dataIndex: "cid"
        },
        {
            title: "Payment Method",
            dataIndex: "payment_type"
        },
        {
            title: "Payment Status",
            dataIndex: "pay_status"
        },
        {
            title: "Amount",
            dataIndex: "price"
        },
        {
            title: "Purchase Time",
            dataIndex: "purchase_date"
        },
        {
            title: "Renew Type",
            dataIndex: "renew_type"
        },
        {
            title: "Auto Renew",
            dataIndex: "is_auto_renew"
        },
        {
            title: "Old expired time",
            dataIndex: "old_expired_timestamp"
        },
        {
            title: "New expired time",
            dataIndex: "new_expired_timestamp"
        }
    ];

    export default {
        name: 'pro_transaction',
        components: {
        },
        data() {
            return {
                columns,
                page: 1,
                start_date: moment().add(-30, 'day'),
                end_date: moment(),
                item: {},
                detailVisible: false,
                transaction_list: [],
                total_count: 0
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
            this.search();
        },
        watch: {
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                getProTransactionList: 'monitor/getProTransactionList',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async search() {
                let data = await this.getProTransactionList({
                    page: this.page,
                    stime: this.start_date ? moment(this.start_date).valueOf() : '',
                    etime: this.end_date ? moment(this.end_date).valueOf() : '',
                    page_size: 20
                });

                if (data.code == 100000) {
                    this.transaction_list = data.data.list;
                    this.total_count = data.data.total_count;
                }
            },
            turnPage(e) {
                this.search();
            },
            disabledEndDate(current) {
                return current && current.valueOf() <= moment(this.start_date).valueOf();
            },
        }
    };
</script>
